import ENDPOINTS from "./Endpoints";
import ApiMethods from "./ApiMethods";

class ApiManager {
  static login = async (payload) => {
    const url = ENDPOINTS.LOGIN();
    return ApiMethods.post(url, payload);
  };

  static addUser = async (payload) => {
    const url = ENDPOINTS.ADD_USER();
    return ApiMethods.post(url, payload);
  };

  static getUsers = async (limit, page) => {
    const url = ENDPOINTS.GET_USERS(limit, page);
    return ApiMethods.get(url);
  };

  static getVesselOptions = async (search) => {
    const url = ENDPOINTS.GET_VESSEL_OPTIONS(search);
    return ApiMethods.get(url);
  };

  static getMenu = async () => {
    const url = ENDPOINTS.GET_MENU();

    return ApiMethods.get(url);
  };

  static getPolPodOptions = async (search) => {
    const url = ENDPOINTS.POL_POD_OPTION(search);
    return ApiMethods.get(url);
  };
  static getfinalDestOptions = async (search) => {
    const url = ENDPOINTS.FINAL_DESTI_OPTION(search);
    return ApiMethods.get(url);
  };

  static CheckUser = async (payload) => {
    const url = ENDPOINTS.CHECK_USER();
    return ApiMethods.post(url, payload);
  };

  static rejectUser = async (payload) => {
    const url = ENDPOINTS.REJECT_USER();
    return ApiMethods.post(url, payload);
  };
  static getCompanyOptions = async (role, search) => {
    const url = ENDPOINTS.Company_OPTION(role, search);
    return ApiMethods.get(url);
  };

  static getPLPOByBol = async (bol) => {
    const url = ENDPOINTS.OTM_BOL(bol);
    return ApiMethods.get(url);
  };
  static getSalesOptions = async (name, search) => {
    const url = ENDPOINTS.GET_SALES_OPTIONS(name, search);
    return ApiMethods.get(url);
  };
  static getCityOptions = async (name, search) => {
    const url = ENDPOINTS.GET_CITY_OPTIONS(name, search);
    return ApiMethods.get(url);
  };
  static getCommonOptions = async (name, search) => {
    const url = ENDPOINTS.GET_COMMON_OPTIONS(name, search);
    return ApiMethods.get(url);
  };
  static getAuditDetails = async (id, page, service) => {
    const url = ENDPOINTS.GET_AUDIT_DATA(id, page, service);
    return ApiMethods.get(url);
  };
  static getCustomerFormData = async (source, id) => {
    const url = ENDPOINTS.GET_CUSTOMER_DOCUMENT_FILE(source, id);
    return ApiMethods.get(url);
  };
  static getShipperFormData = async (source, id) => {
    const url = ENDPOINTS.GET_SHIPPER_DOCUMENT_FILE(source, id);
    return ApiMethods.get(url);
  };
  static getCustomerDeatils = async (id) => {
    const url = ENDPOINTS.GET_CUSTOMER_DETAILS(id);
    return ApiMethods.get(url);
  };
  static getShipperDeatils = async (id) => {
    const url = ENDPOINTS.GET_SHIPPER_DETAILS(id);
    const page = "shipper";
    return ApiMethods.get(url, page);
  };
  static getConsigneeDeatils = async (id) => {
    const url = ENDPOINTS.GET_CONSIGNEE_DETAILS(id);
    const page = "consignee";
    return ApiMethods.get(url, page);
  };
  static getIcdDeatils = async (id) => {
    const url = ENDPOINTS.GET_ICD_DETAILS(id);
    const page = "shipper";
    return ApiMethods.get(url, page);
  };
  static downloadDocumnent = async (id, source, sourceId) => {
    const url = ENDPOINTS.DOWNLOAD_DOCUMENT(id, source, sourceId);
    return ApiMethods.get(url);
  };
  static deleteDocument = async (id, source, sourceId) => {
    const url = ENDPOINTS.DOCUMENT_DELETE(id, source, sourceId);
    return ApiMethods.delete(url);
  };
  static approveCustomerApprove = async (id, type) => {
    const url = ENDPOINTS.CUSTOMER_APPROVE_REQUEST(id, type);
    return ApiMethods.put(url);
  };
  static rejectCustomerApprove = async (id, type, remarkMessage) => {
    let payload = { remarks: remarkMessage };
    const url = ENDPOINTS.CUSTOMER_REJECT_REQUEST(id, type);
    return ApiMethods.put(url, payload);
  };
  static fetchDatasExcel = async ({query, payload, service, page}) => {
    const queryString = new URLSearchParams(query).toString();
    const url = ENDPOINTS.FETCH_DATA_EXCEL(queryString, service, page);
    return ApiMethods.postBlob(url, payload);
  };

  static getUserData = async (id) => {
    const url = ENDPOINTS.GET_USER_DATA(id);
    return ApiMethods.get(url);
  };
  static deleteUser = async (id) => {
    const url = ENDPOINTS.DELETE_USER(id);
    return ApiMethods.delete(url);
  };
  static addUserData = async (payload) => {
    const url = ENDPOINTS.ADD_USER_DATA();
    return ApiMethods.post(url, payload);
  };
  static updateUserData = async (payload) => {
    const url = ENDPOINTS.UPDATE_USER_DATA();
    return ApiMethods.put(url, payload);
  };
  static deleteRole = async (id) => {
    const url = ENDPOINTS.DELETE_ROLE(id);
    return ApiMethods.delete(url);
  };
  static addRole = async (payload) => {
    const url = ENDPOINTS.ADD_ROLE();
    return ApiMethods.post(url, payload);
  };
  static removeAssignedUser = (params) => {
    const url = ENDPOINTS.REMOVE_ASSIGNED_USER(params);
    return ApiMethods.delete(url);
  };
  static getRolepermissions = (id) => {
    const url = ENDPOINTS.GET_ROLE_PERMISSIONS(id);
    return ApiMethods.get(url);
  };
  static getRoles = () => {
    const url = ENDPOINTS.GET_ROLES();
    return ApiMethods.get(url);
  };
  static updateRolePermissions = async (payload) => {
    const url = ENDPOINTS.UPDATE_ROLE_PERMISSIONS();
    return ApiMethods.put(url, payload);
  };
  static userPassReset = async (payload) => {
    const url = ENDPOINTS.USER_PASS_RESET();
    return ApiMethods.put(url, payload);
  };

  static fetchEditVessel = (id) => {
    const url = ENDPOINTS.GET_EDIT_VESSEL(id);
    return ApiMethods.get(url);
  };

  static fileDownloadIntegater = async (id) => {
    const url = ENDPOINTS.FILE_DOWNLOAD_INTEGATER(id);
    return ApiMethods.post(url);
  };


  static fetchVesselSuggestions = (inputValue, id) => {
    const url = ENDPOINTS.FETCH_VESSEL_SUGGESTIONS(inputValue, id);
    return ApiMethods.get(url);
  };

  static fetchAutoCompleteData = (inputValue, id) => {
    const url = ENDPOINTS.FETCH_AUTOCOMPLETE_DATA(inputValue, id);
    return ApiMethods.get(url);
  };

  static updateUserProfile = async (payload) => {
    const url = ENDPOINTS.UPDATE_USER_PROFILE();
    return ApiMethods.put(url, payload);
  };
  static updateUserProfileImage = async (payload, id) => {
    const url = ENDPOINTS.UPDATE_USER_PROFILE_IMAGE(id);
    return ApiMethods.put(url, payload);
  };

  static fetchEditVoyage = (id) => {
    const url = ENDPOINTS.GET_EDIT_VOYAGE(id);
    return ApiMethods.get(url);
  };
}

export default ApiManager;
