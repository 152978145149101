export const CARD_CHARGES_COLUMNS =
    [
        {
            flex: 1,
            field: 'status',
            headerName: 'Status',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'chargeName',
            headerName: 'Charge Name',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'chargeFor',
            headerName: 'Charge For',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'chargeCode',
            headerName: 'Charge Code',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'createdBy',
            headerName: 'Created By',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'createdDate',
            headerName: 'Created Date',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'modifiedDate',
            headerName: 'Modified Date',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },

        {
            flex: 1,
            field: 'action',
            headerName: 'Action',
            width: 110,
            headerAlign: 'center',
            align: 'center', editable: false
        }


    ]
