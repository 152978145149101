import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { margin, styled } from "@mui/system";
import { CloudDownload, Delete, Visibility } from "@mui/icons-material"; // Add Visibility icon
import moment from "moment";
import Uploadimg from "../assets/images/upload-placeholder.png";
import ApiManager from "../services/ApiManager";
import { useUploadCustomerFileMutation } from "../store/api/codeDataApi";
import Loader from "./common/Loader/Loader";
import { StyledDataGrid } from "./common/Grid/styles";
import UploadFilesDialog from "./UploadFilesDialog";
import toast, { LoaderIcon } from "react-hot-toast";
import CustomToast from "./common/Toast/CustomToast";
// Custom styled drop zone
const DropZone = styled(Box)(({ theme }) => ({
  border: "2px dashed #ccc",
  borderRadius: "8px",
  padding: theme.spacing(4),
  textAlign: "center",
  width: "300px",
  color: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const UploadFile = ({
  customer_id,
  disabled = false,
  dropdownData,
  sourceType = null,
}) => {
  const [viewloader, setViewloader] = useState(false);
  const [viewloaderId, setViewLoaderId] = useState();
  const [uploadCustomerFile] = useUploadCustomerFileMutation();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [listData, setListData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [viewDocument, setViewDocument] = useState({});
  const [fileData, setFileDaat] = useState({});
  const downloadIntgater = async () => {
    await ApiManager.fileDownloadIntegater(viewloaderId)
      .then((e) =>
        toast.custom(<CustomToast message={e.message} toast="success" />, {
          closeButton: false,
        })
      )
      .catch((e) =>
        toast.custom(<CustomToast message={e.message} toast="error" />, {
          closeButton: false,
        })
      );
  };
  const handleView = async (event, id, source, sourceId, documentType) => {
    setViewloader(true);
    setViewLoaderId(id);
    event.preventDefault();
    try {
      let source = sourceType;
      const res = await ApiManager.downloadDocumnent(id, source, sourceId);
      setFileDaat({
        base64Data: res.body.base64,
        mimeType: res.body.mimeTsype,
      });
      Boolean(res.body.mimeType.includes("spreadsheetml.sheet")) &&
        setFileDaat((prev) => ({ ...prev, documentType: "XL" }));
      Boolean(res.body.mimeType.includes("image")) &&
        setFileDaat((prev) => ({ ...prev, documentType: "IMG" }));
      Boolean(res.body.mimeType.includes("pdf")) &&
        setFileDaat((prev) => ({ ...prev, documentType: "PDF" }));
      Boolean(res.body.mimeType.includes("wordprocessingml")) &&
        setFileDaat((prev) => ({ ...prev, documentType: "MSW" }));
      Boolean(res.body.mimeType.includes("plain")) &&
        setFileDaat((prev) => ({ ...prev, documentType: "TXT" }));
      const binaryString = atob(res.body.base64);
      const binaryArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([binaryArray], { type: res.body.mimeType });
      const url = URL.createObjectURL(blob);
      setViewDocument({ url, documentType });
      setViewDialogOpen(true);
    } catch (error) {
      toast.custom(
        <CustomToast message={"Something went wrong!"} toast="error" />,
        {
          closeButton: false,
        }
      );
    }
    setViewloader(false);
  };
  const handleViewDialogClose = () => {
    setViewDialogOpen(false);
    setViewDocument({});
  };

  const onDelete = async () => {
    try {
      let source = sourceType;
      setLoading(true);
      const res = await ApiManager.deleteDocument(
        deleteData.id,
        deleteData.source,
        deleteData.sourceId
      );

      setOpenConfirmation(false);
      reloadDataHandler();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFileDrop = (event) => {
    const file = (event.target.files || event.dataTransfer.files)[0];
    if (!file) return;
    const maxFileSize = 10 * 1024 * 1024;
    const invalidExtensions = ["zip", "exe"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (file.size > maxFileSize || invalidExtensions.includes(fileExtension)) {
      return toast.custom(
        <CustomToast
          message={
            file.size > maxFileSize
              ? "File size must be less than 10 MB."
              : `.${fileExtension} files are not allowed.`
          }
          toast="error"
        />,
        {
          closeButton: false,
        }
      );
    }
    setUploadedFile(file);
    setDialogOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setUploadedFile(null);
    setFormData({});
  };
  const onCloseConfiramtion = () => {
    setOpenConfirmation(false);
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.documentType) {
      errors.documentType = "Document Type is required";
    }
    if (formData.documentType == "Other") {
      if (!formData.other) {
        errors.other = "Other Type is required";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDialogSave = async () => {
    if (!validateForm()) {
      return;
    }
    const uploadData = {
      file: uploadedFile,
      entityFile: {
        ...formData,
        source: sourceType,
        sourceId: customer_id,
        documentType:
          formData.documentType == "Other"
            ? formData.other
            : formData.documentType,
      },
    };
    try {
      setLoading(true);
      let response = await uploadCustomerFile(uploadData).unwrap();
      setDialogOpen(false);
      reloadDataHandler();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDialogOpen(false);
    }
    setFormData({});
  };

  const handleDate = (date) => {
    if (!date) {
      return "";
    }

    return date.split("T")[0];
  };

  const cusColumns = [
    {
      field: "documentType",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },

    {
      field: "number",
      headerName: "Number",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={`${handleDate(params.value)}`} arrow>
            <div>{handleDate(params.value)}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "modifiedDate",
      headerName: "Issue Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={`${handleDate(params.value)}`} arrow>
            <div>{handleDate(params.value)}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={`${handleDate(params.value)}`} arrow>
            <div>{handleDate(params.value)}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {viewloader && viewloaderId == params.id ? (
            <LoaderIcon />
          ) : (
            <Visibility
              style={{ cursor: "pointer", color: "#1976d2" }}
              onClick={(event) =>
                handleView(
                  event,
                  params.row.id,
                  params.row.source,
                  params.row.sourceId,
                  params.row.fileName
                )
              }
            />
          )}
          <Delete
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              setDeleteData({
                id: params.row.id,
                source: params.row.source,
                sourceId: params.row.sourceId,
                fileName: params.row.fileName,
              });
              setOpenConfirmation(true);
            }}
            disabled={disabled}
          />
        </div>
      ),
    },
  ];
  const columns = [
    {
      field: "documentType",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.row.createdBy}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={`${params.row.modifiedBy}`} arrow>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={`${handleDate(params.value)}`} arrow>
            <div>{handleDate(params.value)}</div>;
          </Tooltip>
        );
      },
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title={`${handleDate(params.value)}`} arrow>
            <div>{handleDate(params.value)}</div>;
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {viewloader && viewloaderId == params.id ? (
            <LoaderIcon />
          ) : (
            <Visibility
              style={{ cursor: "pointer", color: "#1976d2" }}
              onClick={(event) =>
                handleView(
                  event,
                  params.row.id,
                  params.row.source,
                  params.row.sourceId,
                  params.row.fileName
                )
              }
            />
          )}
          <Delete
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              setDeleteData({
                id: params.row.id,
                source: params.row.source,
                sourceId: params.row.sourceId,
                fileName: params.row.fileName,
              });
              setOpenConfirmation(true);
            }}
            disabled={disabled}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    reloadDataHandler();
  }, []);
  const reloadDataHandler = async () => {
    try {
      let source = sourceType;
      setLoading(true);
      const res = await ApiManager.getCustomerFormData(source, customer_id);
      setListData(res.body);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Loader />
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          paddingLeft={1}
          paddingRight={1}
          marginTop={1}
        >
          <Typography
            variant="h5"
            gutterBottom
            style={{
              width: "100%",
              margin: "0px ! important",
              paddingLeft: "16px",
            }}
          >
            Select Files
          </Typography>
          <Grid item xs={12} sm={4} margin={0} padding={0}>
            <Box display="flex" flexDirection="column" height="100%" gap={2}>
              <DropZone
                onClick={() => document.getElementById("file-input").click()}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleFileDrop(e);
                }}
              >
                <img
                  src={Uploadimg}
                  alt="Upload"
                  style={{ margin: "0 auto" }}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Drop file here or click to{" "}
                  <span
                    style={{ textDecoration: "underline", color: "#1976d2" }}
                  >
                    browse
                  </span>{" "}
                  through your machine
                </Typography>
                <input
                  id="file-input"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileDrop(e);
                    e.target.value = "";
                  }}
                  disabled={disabled}
                />
              </DropZone>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box style={{ height: 400, width: "100%" }}>
              <StyledDataGrid
                rows={listData}
                columns={(sourceType === "CUSTOMER" ? cusColumns : columns).map(
                  (a) => ({
                    ...a,
                    align: "center",
                  })
                )}
                pageSize={20}
                disableSelectionOnClick
              />
            </Box>
          </Grid>

          <UploadFilesDialog
            dialogOpen={dialogOpen}
            handleDialogClose={handleDialogClose}
            dropdownData={dropdownData}
            formData={formData}
            handleInputChange={handleInputChange}
            formErrors={formErrors}
            sourceType={sourceType}
            handleDialogSave={handleDialogSave}
            openConfirmation={openConfirmation}
            onCloseConfiramtion={onCloseConfiramtion}
            deleteData={deleteData}
            onDelete={onDelete}
            viewDialogOpen={viewDialogOpen}
            handleViewDialogClose={handleViewDialogClose}
            viewDocument={viewDocument}
            fileData={fileData}
            downloadIntgater={downloadIntgater}
            setFormData={setFormData}
          />
        </Grid>
      )}
    </>
  );
};

export default UploadFile;
