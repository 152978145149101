export const EXCHANGE_RATE_COLUMNS =
    [
        {
            flex: 1,
            field: 'status',
            headerName: 'Status',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            flex: 1,
            field: 'currency',
            headerName: 'Currency',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            flex: 1,
            field: 'usdExchange',
            headerName: 'Usd Exchange',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            flex: 1,
            field: 'ugxExchange',
            headerName: 'Ugx Exchange',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false,
        },
        {
            flex: 1,
            field: 'createdBy',
            headerName: 'Created By',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'createdDate',
            headerName: 'Created Date',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },
        {
            flex: 1,
            field: 'modifiedDate',
            headerName: 'Modified Date',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            editable: false
        },

        {
            flex: 1,
            field: 'action',
            headerName: 'Action',
            width: 110,
            headerAlign: 'center',
            align: 'center', editable: false
        }


    ]
