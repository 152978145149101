import EditIcon from "@mui/icons-material/Edit";
import BiotechIcon from "@mui/icons-material/Biotech";

export const getCustomerListGridActionsCustomerApprovel = (nav, setModal) => {
  return [
    {
      label: "Edit",
      onClick: (params) => {

        nav(`approveRequest`, {
          state: { formAction: "edit", initialValues: params.row },
        });
      },
      icon: <EditIcon />,
    },
    {
      label: "Audit",
      onClick: (params) => {
        setModal({ open: true, type: "audit", data: params.row });
      },
      icon: <BiotechIcon />,
    },
  ];
};
