// import { appDateFormat } from "../../components/utils/date"


export const CODE_CUSTOMER_COLUMNS = [
    {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        flex: 1,
        field: 'isDoc',
        headerName: 'Document',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },

    {
        flex: 1,
        field: 'customerName',
        headerName: 'Customer Name',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        flex: 1,
        field: 'tinNo',
        headerName: 'TIN NO.',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: false
    },
    {
        flex: 1,
        field: 'vatNo',
        headerName: 'VAT NO.',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: false
    },
    {
        flex: 1,
        field: 'add1',
        headerName: 'Address',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: false
    },
    {
        flex: 1,
        field: 'createdBy',
        headerName: 'Created By',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        flex: 1,
        field: 'createdDate',
        headerName: 'Created Date',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        flex: 1,
        field: 'modifiedBy',
        headerName: 'Modified By',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },
    {
        flex: 1,
        field: 'modifiedDate',
        headerName: 'Modified Date',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        editable: false
    },

    {
        flex: 1,
        field: 'action',
        headerName: 'Action',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: false
    }

]
export const CODE_USER_COLUMNS = [

    {
        field: 'firstName',
        headerName: 'User Name',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        editable: true
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        editable: true
    },
    {
        field: 'phone',
        headerName: 'Phone',
        width: 150,
        headerAlign: 'center',
        align: 'center', editable: true
    },
    {
        flex: 1,
        field: 'address',
        headerName: 'Address',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: true
    },
    {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        width: 110,
        headerAlign: 'center',
        align: 'center', editable: true
    },
    // {
    //     flex: 1,
    //     field: 'action',
    //     headerName: 'Action',
    //     width: 110,
    //     headerAlign: 'center',
    //     align : 'center',editable: true
    // }

]

export const CODE_PARTY_COLUMNS = [
    {
        field: 'acode',
        headerName: 'Code',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'city',
        headerName: 'City',
        width: 150,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'cname',
        headerName: 'PARTY',
        width: 150,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'country',
        headerName: 'Country',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'phone',
        headerName: 'MAIN TEL#',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    }
]

export const AGENT_PARTY_COLUMNS = [
    {
        field: 'acode',
        headerName: 'Code',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'city',
        headerName: 'City',
        width: 150,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'cname',
        headerName: 'AGENT',
        width: 150,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'country',
        headerName: 'Country',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'phone',
        headerName: 'MAIN TEL#',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    },
    {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        width: 110,
        headerAlign: 'center',
        align: 'center'
    }
]