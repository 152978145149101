import ConsigneeScreen from "./ConsigneeScreen";
import Main from "../../components/core/main";

export default function Component({page}) {
  return (
    <>
      {/* <Main /> */}
      <ConsigneeScreen page={page} />
    </>
  );
}
